<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>文字を覚える</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="align" :fullscreen="true">
      <ion-item class="align">
        <ion-label>タップすると音声が流れます</ion-label>
      </ion-item>
      <ion-card
        v-for="(c, index) in chrs"
        :key="index"
        @click="playAudio(c.sort)"
        class="center-card"
      >
        <ion-card-header>
          <ion-card-subtitle>発音: {{ c.pro }}</ion-card-subtitle>
          <ion-card-title class="chr">{{ c.c }}</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          {{ c.sample }}
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";
export default {
  methods: {
    playAudio(i) {
      const music = new Audio(require(`@/assets/sounds/${i}.mp3`));
      music.play();
    },
  },
  data() {
    return {
      targetNum: 0,
      target: { sort: "0", c: "ა", pro: "a", sample: "a as in apple" },
      chrs: [
        { sort: "0", c: "ა", pro: "a", sample: "a as in apple" },
        { sort: "1", c: "ბ", pro: "b", sample: "b as in boy" },
        { sort: "2", c: "გ", pro: "g", sample: "g as in gold" },
        { sort: "3", c: "დ", pro: "d", sample: "d as in dry" },
        { sort: "4", c: "ე", pro: "e", sample: "e as in electricity" },
        { sort: "5", c: "ვ", pro: "v", sample: "v as in vast" },
        { sort: "6", c: "ზ", pro: "z", sample: "z as in zoo" },
        { sort: "7", c: "თ", pro: "t'", sample: "t as in toll" },
        { sort: "8", c: "ი", pro: "i", sample: "I as in Italy" },
        { sort: "9", c: "კ", pro: "k", sample: "k as in karaoke" },
        { sort: "10", c: "ლ", pro: "l", sample: "l as in lamb" },
        { sort: "11", c: "მ", pro: "m", sample: "m as in man" },
        { sort: "12", c: "ნ", pro: "n", sample: "n as in nice" },
        { sort: "13", c: "ო", pro: "o", sample: "o as in old" },
        { sort: "14", c: "პ", pro: "p", sample: "p as in piano" },
        { sort: "15", c: "ჟ", pro: "zh", sample: "zh as in pleasure" },
        { sort: "16", c: "რ", pro: "r", sample: "r as in rabbit" },
        { sort: "17", c: "ს", pro: "s", sample: "s as in smart" },
        { sort: "18", c: "ტ", pro: "t", sample: "t as in tank" },
        { sort: "19", c: "უ", pro: "u", sample: "u as in ultimate" },
        { sort: "20", c: "ფ", pro: "p'", sample: "p as in past" },
        { sort: "21", c: "ქ", pro: "k'", sample: "k as in calm" },
        { sort: "22", c: "ღ", pro: "gh", sample: "no English equivalent" },
        { sort: "23", c: "ყ", pro: "q", sample: "q as in queue" },
        { sort: "24", c: "შ", pro: "sh", sample: "sh as in short" },
        { sort: "25", c: "ჩ", pro: "ch", sample: "'ch as in charm" },
        { sort: "26", c: "ც", pro: "ts", sample: "'ts as in hats" },
        { sort: "27", c: "ძ", pro: "dz", sample: "dz as in sad zebra" },
        { sort: "28", c: "წ", pro: "ts", sample: "ts as in tsunami" },
        { sort: "29", c: "ჭ", pro: "ch", sample: "ch as in charm" },
        {
          sort: "30",
          c: "ხ",
          pro: "kh",
          sample: "kh as in the German bach",
        },
        { sort: "31", c: "ჯ", pro: "j	", sample: "j	as in pleasure" },
        { sort: "32", c: "ჰ", pro: "h	", sample: "h	as in Hawaii" },
      ],
    };
  },
  name: "Tab1",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
  },
};
</script>
<style scoped>
.center-card {
  text-align: center;
}
.chr {
  font-size: 400%;
}
.align {
  text-align: center;
}
</style>